export enum QUERY_KEYS {
  VERIFY_PROMO_CODE = 'VERIFY_PROMO_CODE',
  GET_DEPLOYMENTS = 'GET_ROLLUP_DEPLOYMENTS',
  GET_AVS_DEPLOYMENTS = 'GET_AVS_DEPLOYMENTS',
  GET_AGGREGATORS_STATUS = 'GET_AGGREGATORS_STATUS',
  CREATE_AVS = 'CREATE_AVS',
  GET_AVS_OPERATORS = 'GET_AVS_OPERATORS',
  GET_AVS_METADATA = 'GET_AVS_METADATA',
  GET_OPERATOR_STATUS = 'GET_OPERATOR_STATUS',
  GET_GLOBAL_AVS = 'GET_GLOBAL_AVS',
  GET_AVS_MARKETPLACE_APPS = 'GET_AVS_MARKETPLACE_APPS',
  GET_AVS_MARKETPLACE_APP = 'GET_AVS_MARKETPLACE_APP',
  GET_AVS_MARKETPLACE_CONNECTED_APPS = 'GET_AVS_MARKETPLACE_CONNECTED_APPS',
}

export enum MUTATION_KEYS {
  AVS_MARKETPLACE_DEPLOY_APPS = 'AVS_MARKETPLACE_DEPLOY_APPS',
  PROMOTE_TEAM_MEMBER = 'PROMOTE_TEAM_MEMBER',
  KICK_TEAM_MEMBER = 'KICK_TEAM_MEMBER',
}
