import { AVS_DIRECTORY_ADDR, DELEGATION_MANAGER_ADDR, REWARDS_COORDINATOR_ADDR } from './addresses';

export const staticArgs = [
  { name: '__avsDirectory', value: AVS_DIRECTORY_ADDR },
  { name: '__rewardsCoordinator', value: REWARDS_COORDINATOR_ADDR },
  { name: '__delegationManager', value: DELEGATION_MANAGER_ADDR },
];

export const deferredArgNames = ['__registryCoordinator', '__stakeRegistry', 'pauserRegistry_'];

export const ERR_MSG_COMPILE_REQUIRED = 'Your contract has not been successfully compiled.';

export const MACH_REQUIRED_FN_NAMES = [
  'allowlistEnabled',
  'enableAllowlist',
  'disableAllowlist',
  'getAllowlistSize',
  // 'queryOperators',
  'getRestakeableStrategies',
  'updateAVSMetadataURI',
  'setAllowlist',
  'createAVSRewardsSubmission',
  'owner',
  // 'allowlistManager',
  'paused',
  'transferOwnership',
  // 'setAllowlistManager',
  'initialize',
];

// Other BLS (excludes Mach)
export const BLS_REQUIRED_FN_NAMES = [
  'allowlistEnabled',
  'enableAllowlist',
  'disableAllowlist',
  'getAllowlistSize',
  'queryOperators',
  'getRestakeableStrategies',
  'updateAVSMetadataURI',
  'setAllowlist',
  'createAVSRewardsSubmission',
  'owner',
  'allowlistManager',
  'paused',
  'transferOwnership',
  'setAllowlistManager',
  'initialize',
];

export const ECDSA_REQUIRED_FN_NAMES = [
  'allowlistEnabled',
  'enableAllowlist',
  'disableAllowlist',
  'getAllowlistSize',
  'queryOperators',
  'getRestakeableStrategies',
  'updateAVSMetadataURI',
  'setAllowlist',
  'createAVSRewardsSubmission',
  'owner',
  'allowlistManager',
  // paused,
  'transferOwnership',
  'setAllowlistManager',
  'initialize',
];

export const MACH_CONTRACT_NAME = 'MachServiceManager';

export const CUSTOM_CONTRACT_NAMES = [
  'GenericTemplate',
  'CoprocessorTemplate',
  'BridgeTemplate',
  MACH_CONTRACT_NAME,
  'HelloWorldTemplate',
];
