import { Grid, useTheme } from '@mui/material';

export interface IAvsMarketplaceConnectedAppCard {
  children: React.ReactNode;
  background?: string;
  onClick?: () => void;
}

export const AvsMarketplaceConnectedAppCard = ({
  background,
  children,
  onClick,
}: IAvsMarketplaceConnectedAppCard) => {
  const theme = useTheme();

  return (
    <Grid
      alignItems="center"
      container
      flexDirection="column"
      height="96px"
      item
      justifyContent="center"
      onClick={onClick}
      sx={{
        background: background ?? theme.colors.gradients.sheet,
        cursor: 'pointer',
      }}
      width={'378px'}
      xs={3}
    >
      {children}
    </Grid>
  );
};
