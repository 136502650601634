import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CircularProgress,
  Grid,
  Link,
  Stack,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {
  fetchAggregator,
  fetchAllAggregatorsStatus,
  fetchAllAvs,
  fetchGlobalPublicAvs,
} from 'api/avs';
import CopyableText from 'components/Copyable/CopyableText';
import IconInfo from 'components/icons/IconInfo';
import IconLink from 'components/icons/IconLink';
import { PageBanner } from 'components/Layout/PageBanner';
import { UserGuideButton } from 'components/UserGuideButton';
import { avsStatusLabelMap, avsTypeLabels } from 'labels/deployments';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { AVS_QUORUMS, AVS_TYPES } from 'types/avs';
import { AVSDeployment } from 'types/protoc-gen/avs';
import { QUERY_KEYS } from 'types/react-query';
import { Address } from 'viem';
import { ObjectSchema } from 'yup';

import ActivateHostedOperatorWizard from './ActivateHostedOperatorWizard';
import AddRewardsModal from './AddRewardsModal';
import ContractAddressesDialog from './ContractAddressesDialog';
import ContractRolesDialog from './ContractRolesDialog';
import EditMetadataModal from './EditMetadataModal';
import EditStrategies from './EditStrategies';
import SelectOperators from './SelectOperators';
import UpdateStakeBanner from './UpdateStakeBanner';
import UpgradeSvcMgrDialog from './UpgradeSvcMgrDialog';
import { schema } from './validationSchema';

function InfoHeader({ children, sx }: TypographyProps) {
  return (
    <Typography
      sx={{
        background: 'linear-gradient(155deg, #FFF 0%, #F7F7F7 100%)',
        px: 5,
        py: 3,
        height: '5rem',
        boxShadow: '0px 0px 2px rgba(32, 39, 35, 0.15)',
        ...sx,
      }}
      variant="h5"
    >
      {children}
    </Typography>
  );
}

function DetailsButton({ children, onClick, ...rest }: TypographyProps) {
  return (
    <Typography
      onClick={onClick}
      sx={{ display: 'flex', alignItems: 'center', gap: 2, cursor: 'pointer' }}
      variant="bodySmallC"
      {...rest}
    >
      <Box component="img" src="/imgs/iconArrowCircleFilled.svg" sx={{ width: 40, height: 40 }} />
      {children}
    </Typography>
  );
}

export interface AVSDetailsFormValues {
  strategies: AVS_QUORUMS[];
  operators: Address[];
}

export function AVSDetails() {
  const params = useParams();
  const deploymentId = params?.deploymentId;

  const { data: avsDeployments, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_AVS_DEPLOYMENTS],
    queryFn: fetchAllAvs,
  });
  const { data: globalAvsDeployments, isFetching: isFetchingGlobal } = useQuery({
    queryKey: [QUERY_KEYS.GET_GLOBAL_AVS],
    queryFn: fetchGlobalPublicAvs,
  });
  const avs =
    avsDeployments?.find(cur => cur.deploymentId === deploymentId) ||
    globalAvsDeployments?.avsDeployment?.find(cur => cur.deploymentId === deploymentId) ||
    ({} as AVSDeployment);
  const isAvsOwner = Boolean(avsDeployments?.find(cur => cur.deploymentId === deploymentId));

  const { data: aggregatorInfo } = useQuery({
    queryKey: ['GET_AGGREGATOR'],
    queryFn: fetchAggregator,
  });

  const form = useForm<AVSDetailsFormValues>({
    mode: 'onTouched',
    defaultValues: {
      strategies: [AVS_QUORUMS.ETH_LST],
    },
    resolver: yupResolver(schema as ObjectSchema<AVSDetailsFormValues>),
  });

  const onSubmit: SubmitHandler<AVSDetailsFormValues> = data => {
    console.log(data);
    // create(data);
  };

  const onError: SubmitErrorHandler<AVSDetailsFormValues> = formErrors => {
    console.error(formErrors);
  };

  const [visited, setVisited] = useState<boolean>(false);
  const [showContractAddresses, setShowContractAddresses] = useState(false);
  const [showContractRolesDialog, setShowContractRolesDialog] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showHostedOperatorWizard, setShowHostedOperatorWizard] = useState(false);
  const [showEditMetadataModal, setShowEditMetadatamodal] = useState(false);
  const [showAddRewardsModal, setShowAddRewardsModal] = useState(false);

  const { data: aggregatorStatuses } = useQuery({
    queryKey: [QUERY_KEYS.GET_AGGREGATORS_STATUS],
    queryFn: fetchAllAggregatorsStatus,
  });

  const handleClickUserGuideButton = useCallback(() => {
    window.open('https://docs.altlayer.io/altlayer-documentation/wizard/manage-avs', '_blank');
    localStorage.setItem('avs.manage.user-guide', 'true');
  }, []);

  useEffect(() => {
    const visited = localStorage.getItem('avs.manage.user-guide');

    if (visited === 'true') {
      setVisited(true);
    } else {
      setVisited(false);
    }
  }, []);

  return (
    <>
      <UpgradeSvcMgrDialog
        avs={avs}
        onClose={() => setShowUpgradeModal(false)}
        open={showUpgradeModal}
      />
      <ContractAddressesDialog
        avs={avs}
        onClose={() => setShowContractAddresses(false)}
        open={showContractAddresses}
      />
      <ContractRolesDialog
        avs={avs}
        isAvsOwner={isAvsOwner}
        onClose={() => setShowContractRolesDialog(false)}
        open={showContractRolesDialog}
      />
      <EditMetadataModal
        avs={avs}
        onClose={() => setShowEditMetadatamodal(false)}
        open={showEditMetadataModal}
      />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit, onError)}>
          {showHostedOperatorWizard && (
            <ActivateHostedOperatorWizard
              avs={avs}
              onClose={() => setShowHostedOperatorWizard(false)}
              open={true}
            />
          )}
          <AddRewardsModal
            avs={avs}
            onClose={() => setShowAddRewardsModal(false)}
            open={showAddRewardsModal}
          />
          <PageBanner title={avs?.avsName} />
          {!avs && (isFetching || isFetchingGlobal) ? (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ flex: 1, width: '100%', minHeight: '15rem' }}
            >
              <CircularProgress />
            </Stack>
          ) : (
            <Grid
              container
              sx={{
                '&>*': { minHeight: '12rem', boxShadow: '0px 0px 2px rgba(32, 39, 35, 0.15)' },
              }}
            >
              <Grid
                alignItems="center"
                container
                flexDirection="column"
                item
                sx={{ bgcolor: '#FFF' }}
                xs={8}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    background: 'linear-gradient(119deg, #F6F6F6 0%, #E7E7E7 100%)',
                    height: '5rem',
                    px: 5,
                    boxShadow: '0px 0px 2px rgba(32, 39, 35, 0.15)',
                  }}
                  width="100%"
                >
                  <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Stack alignItems="center" direction="row" gap={2}>
                      {
                        avsStatusLabelMap?.[
                          String(
                            avs?.avsType === AVS_TYPES.ECDSA_HELLO_WORLD
                              ? 'running'
                              : aggregatorStatuses?.find(cur => cur?.avsName === avs?.avsName)
                                  ?.status,
                          )
                        ]
                      }
                      <Typography variant="h5">{avs?.avsName}</Typography>
                    </Stack>

                    {!visited && <UserGuideButton onClick={handleClickUserGuideButton} />}
                  </Stack>
                </Stack>

                <Grid
                  container
                  item
                  py={3}
                  sx={{
                    background: '#FAFAFA',
                    boxShadow: '0px 0px 2px rgba(32, 39, 35, 0.15)',
                    minHeight: '5.5rem',
                    '& > * ': {
                      px: 5,
                    },
                  }}
                >
                  <Grid container flexDirection="column" item xs={4}>
                    <Typography variant="caption">Type</Typography>
                    <Typography variant="bodySmallM">
                      {avsTypeLabels[avs?.avsType as AVS_TYPES]}
                    </Typography>
                  </Grid>
                  {(!avs?.avsType || avs?.avsType !== AVS_TYPES.ECDSA_HELLO_WORLD) && (
                    <Grid container flexDirection="column" item xs={8}>
                      <Typography variant="caption">Aggregator endpoint</Typography>
                      <CopyableText
                        showTooltip={false}
                        text={aggregatorInfo?.aggregator?.[0] || ''}
                      >
                        <Typography variant="bodySmallM">
                          {aggregatorInfo?.aggregator?.[0]}
                        </Typography>
                      </CopyableText>
                    </Grid>
                  )}
                  <UpdateStakeBanner avs={avs} />
                  <Grid item xs={12}>
                    <EditStrategies avs={avs} isAvsOwner={isAvsOwner} sx={{ '&': { pl: 0 } }} />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectOperators avs={avs} isAvsOwner={isAvsOwner} sx={{ '&': { pl: 0 } }} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                flexDirection="column"
                item
                sx={{ bgcolor: '#fff', height: 'max-content' }}
                xs={4}
              >
                <InfoHeader>AVS Details</InfoHeader>
                <Stack
                  sx={{
                    flex: 1,
                    background: '#FFF',
                    '&>*': {
                      px: 5,
                      py: 3,
                    },
                    boxShadow: '0px 0px 2px rgba(32, 39, 35, 0.15)',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                    }}
                  >
                    <Typography
                      component={Link}
                      href={`https://holesky.eigenlayer.xyz/avs/${avs?.serviceManagerProxy}`}
                      rel="noopener noreferrer"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        cursor: 'pointer',
                        '&:hover': { textDecoration: 'underline' },
                      }}
                      target="_blank"
                    >
                      <Box
                        component="img"
                        src="/imgs/iconArrowCircleFilled.svg"
                        sx={{ width: 40, height: 40 }}
                      />

                      <Typography
                        sx={{
                          position: 'relative',
                          left: -7,
                        }}
                        variant="bodySmallC"
                      >
                        Info page
                        <IconLink sx={{ ml: 1, position: 'relative', top: 3 }} />
                      </Typography>
                    </Typography>

                    <Tooltip
                      title={<Typography variant="caption">View onboarding guide</Typography>}
                    >
                      <Box
                        component={Link}
                        href="https://docs.eigenlayer.xyz/eigenlayer/avs-guides/avs-dashboard-onboarding"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <IconInfo />
                      </Box>
                    </Tooltip>
                  </Box>

                  <DetailsButton onClick={() => setShowContractAddresses(true)}>
                    Contract Addresses
                  </DetailsButton>
                  <DetailsButton onClick={() => setShowContractRolesDialog(true)}>
                    Contract Roles
                  </DetailsButton>
                </Stack>
                {isAvsOwner && (
                  <>
                    <InfoHeader>Manage AVS</InfoHeader>
                    <Stack
                      sx={{
                        flex: 1,
                        background: '#FFF',
                        '&>*': {
                          px: 5,
                          py: 3,
                        },
                        boxShadow: '0px 0px 2px rgba(32, 39, 35, 0.15)',
                      }}
                    >
                      <DetailsButton onClick={() => setShowEditMetadatamodal(true)}>
                        Edit AVS Profile
                      </DetailsButton>
                      <DetailsButton onClick={() => setShowUpgradeModal(true)}>
                        Upgrade service manager
                      </DetailsButton>
                      {avs?.avsType !== AVS_TYPES.ECDSA_HELLO_WORLD && (
                        <DetailsButton onClick={() => setShowHostedOperatorWizard(true)}>
                          Activate hosted operator service
                        </DetailsButton>
                      )}
                      {avs?.avsType !== AVS_TYPES.ECDSA_HELLO_WORLD && (
                        <DetailsButton onClick={() => setShowAddRewardsModal(true)}>
                          Add Rewards
                        </DetailsButton>
                      )}
                    </Stack>
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </form>
        <DevTool control={form.control} />
      </FormProvider>
    </>
  );
}
