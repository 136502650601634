import { Box, Collapse, Grid, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { fetchAllAggregatorsStatus, fetchAllAvs } from 'api/avs';
import { PATHS } from 'App';
import { PageBanner } from 'components/Layout/PageBanner';
import { SubHeader } from 'components/Layout/SubHeader';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useGlobalPublicAvs } from 'hooks/api/useGlobalPublicAvs';
import { useDisclosure } from 'hooks/ui/useDisclosure';
import { useIsProduction } from 'hooks/ui/useIsProduction';
import { useState } from 'react';
import { IoAddSharp } from 'react-icons/io5';
import { PiCaretDownBold } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import { MarketplaceApp } from 'types/marketplace';
import { AVSDeployment } from 'types/protoc-gen/avs';
import { QUERY_KEYS } from 'types/react-query';

import { AvsDeploymentCard } from './components/AvsDeploymentCard';
import { AvsMarketplaceConnectedAppCard } from './components/AvsMarketplaceConnectedAppCard';
import { AvsMarketplaceDrawer } from './components/AvsMarketplaceDrawer';
import { GlobalAvsDeployments } from './GlobalAvsDeployments';

export function AVSDeployments() {
  const isProd = useIsProduction();
  const navigate = useNavigate();
  const { addAlert } = useAlerts();
  const [showGlobal, setShowGlobal] = useState(false);

  const {
    onClose: onCloseAppStoreDrawer,
    onOpen: onOpenAppStoreDrawer,
    open: appStoreDrawerOpened,
  } = useDisclosure();

  const {
    data: avsDeployments,
    isFetching: isFetchingAvsDeployments,
    isLoading: isLoadingAvsDeployments,
  } = useQuery({
    queryKey: [QUERY_KEYS.GET_AVS_DEPLOYMENTS],
    queryFn: fetchAllAvs,
  });
  const {
    data: aggregators,
    isFetching: isFetchingAggregators,
    isLoading: isLoadingAggregators,
  } = useQuery({
    queryKey: [QUERY_KEYS.GET_AGGREGATORS_STATUS],
    queryFn: fetchAllAggregatorsStatus,
  });
  const {
    data: globalAvsDeployments,
    isFetching: isFetchingGlobalAvs,
    isLoading: isLoadingGlobalAvs,
  } = useGlobalPublicAvs({
    enabled: Boolean(showGlobal),
  });
  const { data: connectedApps } = useQuery({
    queryKey: [QUERY_KEYS.GET_AVS_MARKETPLACE_CONNECTED_APPS],
    queryFn: (): Promise<MarketplaceApp[]> => {
      // TODO: for removal after API integration
      return new Promise<MarketplaceApp[]>((resolve, reject) => {
        if (isProd) {
          resolve([]);
        } else {
          setTimeout(() => {
            if (Math.random() < 0.5) {
              reject(new Error('Random error occurred'));
              addAlert({
                severity: ALERT_SEVERITY.ERROR,
                title: 'Failed to fetch apps',
                desc: 'Failed to fetch apps from the marketplace, retrying...',
              });
            } else {
              resolve(getDummyConnectedApps());
            }
          }, 3_000);
        }
      });
    },
    retry: 3,
    retryDelay: 3_000,
    enabled: !isProd,
  });

  return (
    <>
      <PageBanner title="AVS Dashboard" />
      <SubHeader
        isLoading={
          (isLoadingAvsDeployments && isFetchingAvsDeployments) ||
          (isLoadingAggregators && isFetchingAggregators)
        }
      >
        Your Deployments
      </SubHeader>
      <Grid
        container
        sx={{ '&>*': { minHeight: '12rem', boxShadow: '0px 0px 2px rgba(32, 39, 35, 0.15)' } }}
      >
        {avsDeployments?.map(avs => (
          <Grid item key={avs.deploymentId} xs={4}>
            <AvsDeploymentCard
              aggregator={aggregators?.find(agg => agg.avsName === avs?.avsName)}
              avsDeployment={avs}
            />
          </Grid>
        ))}
        <Grid
          alignItems="center"
          container
          flexDirection="column"
          item
          justifyContent="center"
          onClick={() => navigate(PATHS.NEW_AVS)}
          sx={{ bgcolor: '#fff', cursor: 'pointer' }}
          xs={4}
        >
          <IoAddSharp size={24} />
          <Typography mt={3} variant="bodySmallC">
            New AVS
          </Typography>
        </Grid>
      </Grid>

      {!isProd && (
        <>
          <SubHeader>Your connected apps [Feature coming soon]</SubHeader>
          <Grid
            container
            sx={{
              background: '#fafafa',
              pb: '50px',
              '&>*': {
                minHeight: '6rem',
                boxShadow: '0px 0px 2px rgba(32, 39, 35, 0.15)',
              },
            }}
          >
            {connectedApps?.map((app, index) => (
              <AvsMarketplaceConnectedAppCard key={app.id}>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="start"
                  px={'40px'}
                  spacing="16px"
                  width="100%"
                >
                  <Typography
                    alignItems="center"
                    bgcolor={
                      [
                        'green',
                        'gainsboro',
                        'wheat',
                        'violet',
                        'turquoise',
                        'tomato',
                        'teal',
                        'silver',
                        'coral',
                        'crimson',
                        'lemonchiffon',
                        'oldlace',
                      ][index] ?? 'blanchedalmond'
                    }
                    borderRadius={index % 2 === 0 ? '50%' : '0'}
                    color="white"
                    display="flex"
                    height="48px"
                    justifyContent="center"
                    variant="body2"
                    width="48px"
                  >
                    {app.name.slice(0, 2).toUpperCase()}
                  </Typography>
                  <Typography variant="body2">{app.name}</Typography>
                </Stack>
              </AvsMarketplaceConnectedAppCard>
            ))}
            <AvsMarketplaceConnectedAppCard background="#fff" onClick={onOpenAppStoreDrawer}>
              <IoAddSharp size={24} />
            </AvsMarketplaceConnectedAppCard>
          </Grid>
        </>
      )}

      <Box sx={{ mb: '164px' }}>
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
          onClick={() => setShowGlobal(prev => !prev)}
          sx={{
            background: '#fafafa',
            px: 5,
            py: 4,
            border: '1px solid rgba(32, 39, 35, 0.08)',
            cursor: 'pointer',
          }}
        >
          <Typography variant="h5">{showGlobal ? '' : 'Browse '} Global AVS</Typography>
          <Box
            component={PiCaretDownBold}
            size={20}
            sx={{
              transform: showGlobal ? 'rotate(-180deg)' : 'none',
              transition: 'all 0.2s ease-in-out',
            }}
          />
        </Grid>
        <Collapse in={showGlobal} mountOnEnter>
          <GlobalAvsDeployments
            data={globalAvsDeployments?.avsDeployment || ([] as AVSDeployment[])}
            isLoading={isLoadingGlobalAvs && isFetchingGlobalAvs}
          />
        </Collapse>
      </Box>

      <AvsMarketplaceDrawer onClose={onCloseAppStoreDrawer} open={appStoreDrawerOpened} />
    </>
  );
}

// TODO: for removal after API integration
const getDummyConnectedApps = (): MarketplaceApp[] => {
  return [
    {
      id: '1',
      name: 'App 1',
    },
    {
      id: '2',
      name: 'App 2',
    },
    {
      id: '3',
      name: 'App 3',
    },
    {
      id: '4',
      name: 'App 4',
    },
    {
      id: '5',
      name: 'App 5',
    },
    {
      id: '6',
      name: 'App 6',
    },
    {
      id: '7',
      name: 'App 7',
    },
  ];
};
