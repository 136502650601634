import { Box, Checkbox, Grid, Stack, Typography, useTheme } from '@mui/material';
import { MarketplaceApp } from 'types/marketplace';

export interface IAvsMarketplaceAppCard {
  app: MarketplaceApp;
  selected?: boolean;
  onClick: () => void;
}

export const AvsMarketplaceAppCard = ({ app, onClick, selected }: IAvsMarketplaceAppCard) => {
  const theme = useTheme();

  return (
    <Grid
      height="180px"
      onClick={onClick}
      sx={{
        background: theme.colors.gradients.sheet,
        cursor: 'pointer',
        border: `1px solid ${selected ? '#5C8CE1' : 'rgba(32, 39, 35, 0.08)'}`,
        p: '24px',
      }}
      width="100%"
    >
      <Grid alignItems="start" display="flex" justifyContent="space-between" mb="24px">
        {' '}
        <Stack alignItems="center" direction="row" spacing="16px">
          <Box
            alignItems="center"
            bgcolor={
              [
                'green',
                'gainsboro',
                'wheat',
                'violet',
                'turquoise',
                'tomato',
                'teal',
                'silver',
                'coral',
                'crimson',
                'lemonchiffon',
                'oldlace',
              ][+app.id] ?? 'blanchedalmond'
            }
            borderRadius={+app.id % 2 === 0 ? '50%' : '0'}
            display="flex"
            height="48px"
            justifyContent="center"
            width="48px"
          >
            <Typography color="#FFF">{app.name.slice(0, 2).toUpperCase()}</Typography>
          </Box>
          <Typography>{app.name}</Typography>
        </Stack>
        <Checkbox
          checked={selected}
          sx={{
            color: theme.palette.text.primary,
            '&.Mui-checked': {
              color: theme.colors.functional.text.link,
            },
          }}
        />
      </Grid>
      <Grid>
        <Typography
          sx={{
            mr: '24px',
            textAlign: 'justify',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'text',
          }}
          variant="caption"
        >
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla, odio officia neque sint
          quaerat doloremque nostrum ipsam quis qui iusto aperiam repellendus eaque consequuntur
          commodi magni repudiandae voluptas impedit eveniet!
        </Typography>
      </Grid>
    </Grid>
  );
};
