import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  DrawerProps,
  Grid,
  IconButton,
  Skeleton,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useIsProduction } from 'hooks/ui/useIsProduction';
import { useCallback } from 'react';
import { RxCross1 } from 'react-icons/rx';
import { useAvsMarketplaceStore } from 'stores';
import { MarketplaceApp } from 'types/marketplace';
import { MUTATION_KEYS, QUERY_KEYS } from 'types/react-query';

import { AvsMarketplaceAppCard } from './AvsMarketplaceAppCard';

export interface IAvsMarketplaceDrawer extends Omit<DrawerProps, 'children' | 'onClose'> {
  onClose: () => void;
}

export const AvsMarketplaceDrawer = ({ onClose, open, ...drawerProps }: IAvsMarketplaceDrawer) => {
  const theme = useTheme();
  const isProd = useIsProduction();
  const { addAlert } = useAlerts();
  const { addSelectedApp, appSelected, mockApps, removeSelectedApp, selectedApps } =
    useAvsMarketplaceStore();

  const handleSelectItem = useCallback(
    (app: MarketplaceApp) => {
      if (selectedApps.includes(app)) {
        return removeSelectedApp(app);
      }

      return addSelectedApp(app);
    },
    [addSelectedApp, removeSelectedApp, selectedApps],
  );

  const { data: apps, isPending: getMarketplaceAppsPending } = useQuery({
    queryKey: [QUERY_KEYS.GET_AVS_MARKETPLACE_APPS],
    queryFn: (): Promise<MarketplaceApp[]> => {
      // TODO: replace with API call when ready
      return new Promise<MarketplaceApp[]>((resolve, reject) => {
        if (isProd) {
          resolve([]);
        } else {
          setTimeout(() => {
            if (Math.random() < 0.5) {
              reject(new Error('Random error occurred'));
              addAlert({
                severity: ALERT_SEVERITY.ERROR,
                title: 'Failed to fetch apps',
                desc: 'Failed to fetch apps from the marketplace, retrying...',
              });
            } else {
              resolve(mockApps);
            }
          }, 3_000);
        }
      });
    },
    retry: 3,
    retryDelay: 3_000,
  });

  const { isPending: submitDeployAppsPending, mutate: submitDeployApps } = useMutation({
    mutationKey: [MUTATION_KEYS.AVS_MARKETPLACE_DEPLOY_APPS],
    mutationFn: ({ selectedApps }: { selectedApps: MarketplaceApp[] }) => {
      console.log('selectedApps:', selectedApps);

      // TODO: replace with API call when ready
      return new Promise<void>((resolve, reject) => {
        if (isProd) resolve();

        setTimeout(() => {
          if (Math.random() < 0.5) {
            reject(new Error('Random error occurred'));
          } else {
            resolve();
          }
        }, 3_000);
      });
    },
    onSuccess: () => {
      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: 'Apps submitted',
        desc: 'Apps have been submitted for deployment',
      });
    },
    onError: () => {
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: 'Apps submission failed',
        desc: 'Failed to submit apps for deployment',
      });
    },
  });

  return (
    <Drawer anchor="bottom" onClose={onClose} open={open} {...drawerProps}>
      <Box height="94vh" pb="88px">
        <Toolbar
          sx={{
            mb: '40px',
            pt: '40px',
            mx: '20px',
            alignItems: 'center',
            direction: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h5">App Store</Typography>
          <IconButton aria-label="close-app-store-bottom-sheet" onClick={onClose}>
            <RxCross1 size="24px" />
          </IconButton>
        </Toolbar>

        <Box
          mb="64px"
          sx={{
            height: '80%',
            overflowY: 'auto',
          }}
        >
          <Grid
            alignItems="center"
            container
            display="flex"
            justifyContent="center"
            px="40px"
            spacing="20px"
          >
            {getMarketplaceAppsPending
              ? Array.from({ length: 12 }).map((_, index) => (
                  <Grid
                    item
                    key={index}
                    xs={4} // setting it to 4 breaks the layout
                  >
                    <Skeleton
                      height="180px"
                      sx={{ borderRadius: 0, m: 0, p: 0 }}
                      variant="rectangular"
                    />
                  </Grid>
                ))
              : apps?.map(app => (
                  <Grid
                    item
                    key={app.id}
                    xs={4} // setting it to 4 breaks the layout
                  >
                    <AvsMarketplaceAppCard
                      app={app}
                      onClick={() => handleSelectItem(app)}
                      selected={appSelected(app)}
                    />
                  </Grid>
                ))}
          </Grid>
        </Box>

        <Box alignItems="center" display="flex" justifyContent="center">
          <Button
            onClick={() =>
              !submitDeployAppsPending ? submitDeployApps({ selectedApps }) : undefined
            }
            sx={{
              height: '68px',
              width: '378px',
              mb: '40px',
              boxShadow: 0,
              background: theme.colors.gradients.water,
              '&:hover': {
                boxShadow: 0,
                background: theme.colors.gradients.water,
              },
            }}
          >
            {submitDeployAppsPending ? (
              <CircularProgress size={24} />
            ) : (
              <Typography color={theme.palette.text.primary} variant="bodySmall">
                Deploy
              </Typography>
            )}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};
