import { QueryClient } from '@tanstack/react-query';
import {
  BLS_REQUIRED_FN_NAMES,
  deferredArgNames,
  ECDSA_REQUIRED_FN_NAMES,
  ERR_MSG_COMPILE_REQUIRED,
  MACH_CONTRACT_NAME,
  MACH_REQUIRED_FN_NAMES,
} from 'constants/customAvs';
import { AVS_TYPES } from 'types/avs';
import { ListAllAVSDeploymentAdminResponse } from 'types/protoc-gen/avs';
import { QUERY_KEYS } from 'types/react-query';
import * as yup from 'yup';

export const getDeploymentNameValidation = (queryClient?: QueryClient) =>
  yup
    .string()
    .required()
    .noUrl()
    .min(3)
    .max(20)
    .matches(/^[0-9a-z-]+$/, "Please use lowercase alphanumeric characters or '-' only.")
    .matches(/^[^-].*[^-]$/, "Cannot begin or end with '-'.")
    .matches(/^(?!.*-{2}).*$/, "'-' cannot be used consecutively.")
    .matches(/[a-z0-9]([-a-z0-9]*[a-z0-9])/, 'Does not match regex [a-z0-9]([-a-z0-9]*[a-z0-9])')
    .test('checkAvsNameUnique', 'This name is already taken.', value => {
      const deployments = queryClient?.getQueryData<ListAllAVSDeploymentAdminResponse>([
        QUERY_KEYS.GET_GLOBAL_AVS,
      ]);

      console.log('deployments:', deployments);

      return !deployments?.avsDeployment?.some(avs => avs.avsName === value);
    })
    .noLeadingDigit();

export const fnArgsValidation = yup.array().of(
  yup
    .object()
    .shape({
      name: yup.string(),
      type: yup.string(),
      value: yup
        .string()
        .when('type', { is: 'address', then: schema => schema.address() })
        .when('type', {
          is: (type: string) => {
            return type?.startsWith?.('uint');
          },
          then: schema => schema.matches(/^\d+$/, 'Please enter integer values only'),
        })
        .when('name', {
          is: (name: string) => !deferredArgNames?.includes(name),
          then: schema => schema.required(),
        }),
    })
    .required(),
);

export const compiledOutputValidation = yup
  .object()
  .when('avsType', {
    is: AVS_TYPES.CUSTOM_BLS,
    then: schema =>
      schema
        .shape({
          abi: yup
            .array()
            .required(ERR_MSG_COMPILE_REQUIRED)
            .when('contractName', {
              is: MACH_CONTRACT_NAME,
              then: schema =>
                schema.containsAllValuesForProperty(
                  MACH_REQUIRED_FN_NAMES,
                  'name',
                  missingValues =>
                    `Your contract is missing the following required functions:\n ${missingValues
                      ?.map(cur => `\t- '${cur}'`)
                      ?.join('\n')}`,
                ),
              otherwise: schema =>
                schema.containsAllValuesForProperty(
                  BLS_REQUIRED_FN_NAMES,
                  'name',
                  missingValues =>
                    `Your contract is missing the following required functions:\n ${missingValues
                      ?.map(cur => `\t- '${cur}'`)
                      ?.join('\n')}`,
                ),
            }),
          bytecode: yup.string().required(ERR_MSG_COMPILE_REQUIRED),
        })
        .required(ERR_MSG_COMPILE_REQUIRED),
  })
  .when('avsType', {
    is: AVS_TYPES.CUSTOM_ECDSA,
    then: schema =>
      schema
        .shape({
          abi: yup
            .array()
            .required(ERR_MSG_COMPILE_REQUIRED)
            .containsAllValuesForProperty(
              ECDSA_REQUIRED_FN_NAMES,
              'name',
              missingValues =>
                `Your contract is missing the following required functions:\n ${missingValues
                  ?.map(cur => `\t- '${cur}'`)
                  ?.join('\n')}`,
            ),
          bytecode: yup.string().required(ERR_MSG_COMPILE_REQUIRED),
        })
        .required(ERR_MSG_COMPILE_REQUIRED),
  });
