import { MarketplaceApp } from 'types/marketplace';
import { create } from 'zustand';

interface State {
  apps: MarketplaceApp[]; // fetched from API
  installedApps: MarketplaceApp[]; // the installed apps in the organization
  selectedApps: MarketplaceApp[]; // the selected apps in the marketplace drawer
  mockApps: MarketplaceApp[]; // TODO: for removal when API is ready
}

interface Actions {
  appSelected: (app: MarketplaceApp) => boolean;
  setInstalledApps: (apps: MarketplaceApp[]) => void;
  addSelectedApp: (app: MarketplaceApp) => void;
  removeSelectedApp: (app: MarketplaceApp) => void;
}

const initState: State = {
  apps: [],
  installedApps: [],
  selectedApps: [],
  mockApps: [
    {
      id: '1',
      name: 'App 1',
    },
    {
      id: '2',
      name: 'App 2',
    },
    {
      id: '3',
      name: 'App 3',
    },
    {
      id: '4',
      name: 'App 4',
    },
    {
      id: '5',
      name: 'App 5',
    },
    {
      id: '6',
      name: 'App 6',
    },
    {
      id: '7',
      name: 'App 7',
    },
    {
      id: '8',
      name: 'App 8',
    },
    {
      id: '9',
      name: 'App 9',
    },
    {
      id: '10',
      name: 'App 10',
    },
    {
      id: '11',
      name: 'App 11',
    },
    {
      id: '12',
      name: 'App 12',
    },
  ],
};

export const useAvsMarketplaceStore = create<State & Actions>((set, get) => ({
  ...initState,
  appSelected: app => get().selectedApps.includes(app),
  setInstalledApps: apps => set(() => ({ installedApps: apps })),
  addSelectedApp: app => set(state => ({ selectedApps: [...state.selectedApps, app] })),
  removeSelectedApp: app =>
    set(state => ({
      selectedApps: state.selectedApps.filter(selectedApp => selectedApp.id !== app.id),
    })),
}));
