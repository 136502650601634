import { Box, Stack, Typography, useTheme } from '@mui/material';
import { avsStatusLabelMap, avsTypeLabels } from 'labels/deployments';
import { IoArrowForwardSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { AVS_TYPES } from 'types/avs';
import { AVSDeployment, GetAVSStatusByNameResponse } from 'types/protoc-gen/avs';

export const AvsDeploymentCard = ({
  aggregator,
  avsDeployment,
}: {
  avsDeployment: AVSDeployment;
  aggregator?: GetAVSStatusByNameResponse;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        onClick={() => navigate(`/avs/${avsDeployment.deploymentId}`)}
        sx={{
          cursor: 'pointer',
          background: theme.colors.gradients.metal,
          px: 5,
          py: 3,
        }}
      >
        <Typography
          alignItems="center"
          display="flex"
          gap={2}
          justifyContent="space-between"
          variant="bodyBold"
        >
          {
            avsStatusLabelMap[
              String(
                avsDeployment?.avsType === AVS_TYPES.ECDSA_HELLO_WORLD
                  ? 'running'
                  : aggregator?.status,
              )
            ]
          }
          {avsDeployment.avsName}
        </Typography>
        <Box component={IoArrowForwardSharp} size={24} />
      </Stack>
      <Stack px={5} sx={{ background: theme.colors.gradients.sheet, minHeight: '8rem' }}>
        <Stack direction="row" py={3}>
          <Stack flex={1}>
            <Typography variant="caption">Type</Typography>
            <Typography variant="bodySmallM">
              {avsTypeLabels[avsDeployment.avsType as AVS_TYPES]}
            </Typography>
          </Stack>
          {/* <Stack flex={1}>
            <Typography variant="caption">Framework</Typography>
            <Typography variant="bodySmallM">To Do</Typography>
          </Stack> */}
        </Stack>
      </Stack>
    </Stack>
  );
};
