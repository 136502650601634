import { Button, ButtonProps, Stack, SvgIcon } from '@mui/material';

export const UserGuideButton = ({ ...props }: ButtonProps) => (
  <Button
    sx={theme => ({
      color: theme.colors.functional.text.primary,
      background: theme.colors.gradients.water,
      '&:hover': {
        color: theme.colors.functional.text.primary,
        background: theme.colors.gradients.water,
      },
    })}
    {...props}
  >
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      User Guide
      <SvgIcon sx={{ ml: '8px', height: '24px', width: '24px' }} viewBox="0 0 20 20">
        <path
          d="M5.59961 14.4004L14.5996 5.40039M14.5996 5.40039H5.59961M14.5996 5.40039V14.4004"
          stroke="#202723"
          strokeWidth="1.4"
        />
      </SvgIcon>
    </Stack>
  </Button>
);
